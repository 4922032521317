import { CustomInteractionType, getElementWeight, ElementType, IContentElementPieChart, IEntryStatePieChart, QuestionState, PieSlice, IEntryState } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "../../../ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { event } from "jquery";
import { PubSubTypes } from "../../element-render-frame/pubsub/types";

interface Handle {
    x: number,
    y: number,
    sliceIndex: number,
    angle?: number
}

interface Slot {
    x: number,
    y: number,
    angle: number
}

export class PieChartCtrl extends CustomInteractionCtrl {

    element: IContentElementPieChart;
    canvasGr : PIXI.Graphics;
    spriteLoader:SpriteLoader;
    slices : PIXI.Graphics[]
    handles: Handle[]
    slots: Slot[]
    centerX: number;
    centerY: number
    radius: number = 30
    answers

    constructor(element: IContentElementPieChart, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);
        this.element = element;
        if (this.element.pieRadius) {
            this.radius = this.element.pieRadius
        } else {
            if (this.element.canvasWidth != undefined) {
                this.radius = this.element.canvasWidth/2
            }
            if (this.element.canvasHeight != undefined && this.radius*2 > this.element.canvasHeight) {
                this.radius = this.element.canvasHeight/2
            }
        }
        this.spriteLoader = new SpriteLoader()
        this.canvasGr = new PIXI.Graphics();
        this.addGraphic(this.canvasGr)
        const state = this.questionState[this.element.entryId]
        if (!state || !state.answers || state.answers.length==0) {
            const total = this.getTotal()
            this.answers = []
            if (this.element.slices) {
                this.element.slices.forEach((slice:PieSlice, index)=>{
                    if (slice.initialValue || slice.initialValue == 0) {
                        this.answers.push(slice.initialValue)
                    } else {
                        this.answers.push(total/this.element.slices.length)
                    }
                })
            }
        } else {
            this.answers = state.answers
        }
        this.calculateSlots()
        setTimeout(()=>this.updateInputs(), 100)
        this.onAnswerUpdate()
        this.canvasGr.on('mousedown', ($event) => {this.activateMouseDown($event)});
        this.canvasGr.on('mouseup', ($event) => {this.deactivateMouseDown()});
        this.canvasGr.on('mousemove', ($event) => {
            this.mouseMove($event)
        });
        this.canvasGr.interactive = true
    }

    calculateSlots() {
        const gran = this.element.granularity
        if (!gran) {
            this.slots = undefined
            return;
        }
        this.slots = []
        const radius = this.radius
        const numSlots = this.element.totalPieValue/gran
        for (let i = 0;i<numSlots;i++) {
            const angle = i/numSlots*360
            const angleRads = angle/180*Math.PI
            const x = this.radius+Math.cos(angleRads) * (radius);
            const y = this.radius+Math.sin(angleRads) * (radius);
            this.slots.push({
                x,
                y,
                angle
            })
        }
    }

    onAnswerUpdate() {
        //this.roundAnswers()
        this.updatedCircle()
    }

    calculateAnglesBasedOnAnswer() {
        this.handles.forEach((handle, i)=>{
            handle.angle = this.answers[i]/this.element.totalPieValue*360
        })
    }

    getDistSquared(x1, y1, x2, y2) {
        return Math.pow(x1-x2, 2) + Math.pow(y1-y2, 2)
    }

    currentHandle: Handle;

    activateMouseDown($event) {
        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom

        this.throttled_mouse_event(x, y)
    }

    deactivateMouseDown() {
        this.currentHandle = undefined
    }

    mouseMove($event) {
        if (!this.currentHandle) {
            return
        }

        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom

        this.throttled_mouse_event(x, y)
    }

    throttled_mouse_event = _.throttle((xTemp, yTemp) => {
        let x = xTemp
        let y = yTemp
        if (this.questionState && this.questionState[this.element.entryId]) {
            this.questionState[this.element.entryId].isFilled = true
            this.questionState[this.element.entryId].isStarted = true
        }
        const gran = this.element.granularity
        if (gran && this.slots) {
            let nearestSlot = undefined
            this.slots.forEach((slot,i)=>{
                if (i==0 || (this.getDistSquared(xTemp, yTemp, slot.x,slot.y) < this.getDistSquared(xTemp, yTemp, nearestSlot.x, nearestSlot.y))) {
                    nearestSlot = slot
                }
            })
            x = nearestSlot.x 
            y = nearestSlot.y
        }
        let nearestHandle = undefined
        let currentDistSquared = -1
        let index = -1
        this.handles.forEach((handle,i)=>{
            if (!nearestHandle) {
                nearestHandle = handle
                currentDistSquared = this.getDistSquared(handle.x, handle.y, x, y)
                index = i
                return
            }
            const hx = handle.x
            const hy = handle.y
            const tempDist = this.getDistSquared(hx, hy, x, y)
            if (tempDist<currentDistSquared) {
                currentDistSquared = tempDist
                nearestHandle = handle
                index = i
            }
        })
        this.currentHandle = nearestHandle
        const newPoint = this.getNewPoint(x,y,this.radius)
        this.currentHandle.x = newPoint.x
        this.currentHandle.y = newPoint.y
        this.calculateAllAngles()
        this.calculateAnswers()
        this.onAnswerUpdate()
        this.updatedCircle()
        this.updateState()
        this.updateInputs()
        
    }, 20)

    /*roundAnswers() {
        const gran = this.element.granularity
        if (!gran) {
            return false
        }
        const newAnswers = []
        this.answers.forEach((ans)=>{
            const rem = ans%gran
            let newAns = Math.round(ans/gran)*gran
            newAnswers.push(newAns)
        })
        console.log(this.answers)
        console.log(newAnswers)
        this.answers = newAnswers
    }*/

    calcAnsBasedOnGran(ans) {
        let ans1 = ans
        const gran = this.element.granularity
        if (gran) {
            let newAns = Math.round(ans/gran)*gran
            ans1 = newAns
        }
        return ans1
    }

    updateInputs() {
        this.answers.forEach((ans, index)=>{
            const entryId = this.element.slices[index].targetEntryId
            if (entryId || entryId == 0) {
                this.questionPubSub.elementPub(entryId, PubSubTypes.INPUT, this.calcAnsBasedOnGran(ans))
            }
        })
    }

    getNewPoint(mouseX, mouseY, radius) {
        const distX = mouseX - this.centerX
        const distY = mouseY - this.centerY
        
        const totaldist = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2))
        const ratio = radius/totaldist

        const newDistX = distX*ratio + this.centerX
        const newDistY = distY*ratio +this.centerY
        return {x: newDistX, y: newDistY}
    }

    calculateAngle(x1, y1, x2, y2) {
        const dot = x1*x2 + y1*y2
        const det = x1*y2 - y1*x2
        const angle = Math.atan2(det, dot)/Math.PI * 180
        let a =  (angle+360) % 360
        a = (360-a)
        return Number(a.toFixed(0))
    }

    calculateAllAngles() {
        if (this.handles.length<=1) {
            return
        }
        this.calculateThisAngle(this.handles[0], this.handles[this.handles.length-1])
        for (let i = 1;i<this.handles.length;i++) {
            this.calculateThisAngle(this.handles[i], this.handles[i-1])
        }
        /*this.handles.forEach((handle)=>{
            console.log(handle.angle)
        })*/
        //convert from angle to point
    }

    calculateThisAngle(handle, prevHandle) {
        const x = handle.x
        const y = handle.y
        const prevX = prevHandle.x
        const prevY = prevHandle.y
        handle.angle = this.calculateAngle(x-this.centerX, y-this.centerY, prevX-this.centerX, prevY-this.centerY)
    }

    calculateAnswers() {
        this.handles.forEach((handle, i)=>{
            this.answers[i] = this.calcAnsBasedOnGran(handle.angle/360*this.getTotal())
        })
        this.calculateAnglesBasedOnAnswer()
    }

    getTotal() {
        let total = this.element.totalPieValue
        if (!total) {
            total = 100
        }
        return total
    }

    drawWedge(target, x, y, radius, arc, startAngle, hexString, index) {
        target.beginFill(parseInt(hexString, 16));
        const endAngle = startAngle+arc
        const startAngleRads = startAngle/180*Math.PI
        const endAngleRads = endAngle/180*Math.PI
        target.moveTo(x, y);
        target.arc(x, y, radius, startAngleRads, endAngleRads)
        target.endFill();
        if (this.element.slices.length>1) {
            const handleWidth = radius/10
            target.moveTo(x, y);
            const handleAx = x + Math.cos(endAngleRads) * (radius);
            const handleAy = y + Math.sin(endAngleRads) * (radius);
            target.lineStyle(1, 0xffffff, 1)
            target.lineTo(handleAx, handleAy);
            target.lineStyle(0, 0xffffff, 1)
            this.handles.push({
                x: handleAx,
                y: handleAy,
                sliceIndex: index
            })
            //target.arc(x, y, radius+handleWidth, endAngleRads-2, endAngleRads+2)
            //target.lineStyle(0, 0x000000, 1)
            //target.lineTo(x, y);
        }
    }

    updatedCircle() {
        this.slices = []
        this.canvasGr.clear();
        let total = this.getTotal()
        let startAngle = 0;
        if (this.handles && this.handles.length>1) {
            const handle = this.handles[this.handles.length-1]
            startAngle = this.calculateAngle(handle.x-this.centerX, handle.y-this.centerY, this.radius, 0)
        }
        this.centerX = this.radius
        this.centerY = this.radius
        this.handles = []
        if (this.element.slices) {
            this.element.slices.forEach((slice:PieSlice, index:number)=>{
                const val = this.answers[index]
                let hexString = slice.colour.toString(16)
                hexString = hexString.substr(1)
                hexString = "0x"+hexString
                //this.canvasGr.arc(20, 20, 10, 0, Math.PI);
                const arc = val/total*360
                this.drawWedge(this.canvasGr, this.centerX, this.centerY, this.radius, arc, startAngle, hexString, index)
                //this.canvasGr.arc(radius, radius, radius, startAngle, startAngle+arc)
                startAngle += arc
            })
        }
        
        this.canvasGr.zIndex = 10
        this.render();
    }

    /*getInitialState(): Partial<IEntryStatePieChart> {
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.PIE_CHART,
            answers: [],
            isStarted: false,
            isFilled: false,
            isCorrect: false,
            score: 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO
        }
    }*/

    getUpdatedState(): Partial<IEntryStatePieChart> {
        const weight = getElementWeight(this.element)
        let isCorrect = true
        let roundingAllowance = this.element.roundingAllowance
        if (!roundingAllowance) {
            roundingAllowance = 0
        }
        this.answers.forEach((ans, index) => {
            let correctAns = this.element.slices[index].correctAns
            if (!correctAns) {
                correctAns = 0
            }
            const granAns = this.calcAnsBasedOnGran(ans)
            const diff = Math.abs(correctAns - granAns)
            if (diff > roundingAllowance) {
                isCorrect = false
            }
        });
        const qsState = this.questionState ? this.questionState[this.element.entryId] : undefined
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.PIE_CHART,
            answers: this.answers,
            isStarted: qsState && qsState.isStarted ? true : false,
            isFilled: qsState && qsState.isFilled ? true : false,
            isCorrect: isCorrect,
            score: isCorrect ? weight : 0,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        }
    }

    registerMouseEvents(obj:PIXI.Graphics) {
        
    }

    handleNewState(): void {
        let qsState = this.questionState[this.element.entryId]
        if (qsState && qsState.answers) {
            this.answers = qsState.answers
        }
    }

    loadAssets = () => {
        /*let assets = []
        if (this.element && this.element.img && this.element.img.url) {
            assets.push({name: "circle", path: this.element.img.url})
        }
        this.spriteLoader.addSpritestoLoader(assets)*/
        return this.spriteLoader.loadSprites()
    }
}