<div *ngIf="!element" class="notification is-danger is-small">
  Unable to configure voiceover.
</div>

<div *ngIf="element" [ngSwitch]="isResponse">
  <div *ngSwitchCase="true">
      <button 
        *ngIf="!isRecording"
        [disabled]="isDisabled"
        (click)="startRecording()" 
        class="button is-small has-icon"
      >
        <span class=" recorder-circle"></span>
        <span>Start Recording</span>
      </button>
      <button 
        *ngIf="isRecording" 
        (click)="stopRecording()" 
        [disabled]="shouldStop || isDisabled" 
        class="button is-small has-icon"
      >
        <span class=" recorder-circle is-flashing"></span>
        <span>
          Stop Recording
          <span *ngIf="shouldStop">(saving...)</span>
        </span>
      </button>
      <div style="margin:0.5em 0em;" *ngIf="hasAudioUrl()">
        <audio  controls [src]="getAudioUrl()" ></audio>
      </div>
  </div>
  <div *ngSwitchDefault style="margin:0.3em 0em;">
    <div *ngIf="!isEditing">
      <button [disabled]="isDisabled" (click)="toggleEditing()" class="button is-light is-fullwidth has-icon" [class.is-small]="isSmall">
        <span class="icon"><i class="fa fa-microphone" aria-hidden="true"></i>
        </span>
        <span>{{title}}</span>
      </button>
    </div>

    <div [class.is-hidden]="!isEditing" style="background-color:#f1f1f1; border-radius: 0.5em;">

      <div style="margin-bottom:0.5em;">
        <button [disabled]="isDisabled" (click)="toggleEditing()" class="button is-info is-outlined is-fullwidth has-icon"  [class.is-small]="isSmall">
          <span class="icon"><i class="fa fa-microphone" aria-hidden="true"></i> </span>
          <span>{{title}}</span>
        </button>
      </div>

      <audio 
        *ngIf="hasAudioUrl()"
        controls 
        style="min-width:20em;height:2em;width:100%;" 
        [src]="getAudioUrl()"
      ></audio>

      <div style="margin:0.5em 0em; padding: 0.8em;">

        <div style="display:flex; flex-direction: row; justify-content: space-between;">

          <button 
            *ngIf="!isRecording"
            [disabled]="isDisabled"
            (click)="startRecording()" 
            class="button is-small has-icon"
          >
            <span class=" recorder-circle"></span>
            <span>Start Recording</span>
          </button>

          <button 
            *ngIf="isRecording" 
            (click)="stopRecording()" 
            [disabled]="shouldStop || isDisabled" 
            class="button is-small has-icon"
          >
            <span class=" recorder-circle is-flashing"></span>
            <span>
              Stop Recording
              <span *ngIf="shouldStop">(saving...)</span>
            </span>
          </button>

          <!-- <input #recorder style="width: 10em;" type="file" accept="audio/*" capture> -->

          <div>
            <button class="button is-small" [disabled]="isRunningTextToVoice || isDisabled" (click)="runTextToVoice()">
              <i class="fa fa-upload" aria-hidden="true"></i>
            </button>
            <button class="button is-small" (click)="onTriggerMagic()" *ngIf="isMagicEnabled" [disabled]="isDisabled">
              <i class="fa fa-magic" aria-hidden="true"></i>
            </button>
            <button #secretVoiceScriptButton hidden (click)="onTriggerMagic(false)">Hidden Button</button>
          </div>

        </div>


        <div style="margin:0.5em 0em;">
          <textarea
            [formControl]="script"
            rows="2"
            placeholder="Enter script here"
            class="textarea is-small"
            style="flex-grow:1; min-width:unset;"
          ></textarea>
        </div>
      
      </div>

    </div>
  </div>
</div>