<audio id="CallAudio"></audio>
<div class="chat-widget-container">
    <!-- List of Channels -->
    <div *ngIf="isOpen && isLoaded && !openChat" class="chat-view">
        <div class="chat-header">
            
            <div class="field search-bar-container">
                <p class="control has-icons-left">
                    <input (input)="searchChange()" [formControl]="searchField" class="input is-fullwidth search-icon" type="text" placeholder="Search">
                    <span class="icon is-small is-left">
                        <i class="fa fa-search"></i>
                    </span>
                </p>
            </div>
            <i (click)="toggleOpen()" class="fa fa-times fa-2x close-button"></i>
            <!-- <i (click)="toggleSupport()" class="fa fa-question-circle fa-2x help-button"></i> -->
        </div>

        <loading-spinner [isVisible]="chat.isLoading"></loading-spinner>

        <div class="error-screen" *ngIf="chat.errorLoading">
            <i (click)="chat.initSocket()" id="retryIcon" class="fa fa-refresh fa-4x" aria-hidden="true"></i>
            <p>An error has occurred</p>
            <p (click)="chat.initSocket()" id="tryAgain">Try Again</p>
        </div>
        <div *ngIf="!searchResults && !chat.errorLoading && !chat.isLoading" class="chat-body">
            <div class="chat-row-header">
                <p>RECENT</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of chat.allChatData.recent.rows" class="chat-row recent">
                <span *ngIf="row.chatType === ChatRowType.GROUP || row.chatType === ChatRowType.BROADCAST" [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <i *ngIf="row.chatType === ChatRowType.PERSON" class="fa fa-user user-icon" [class.active]="!!row.activeCount"></i>
                <p class="row-title">{{ row.title }}</p>
                <span class="active-call-indicator" *ngIf="row.id == callService.activeCallId && callService.callState != CallState.INACTIVE"><i class="fas fa-phone-volume"></i> Active Call</span>
                <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div>
            </div>

            <div class="chat-row-header">
                <p>MARKING SESSIONS</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of getVisibleGroups()" class="chat-row sessions">
                <span [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <p class="row-title">{{ row.title }}</p>
                <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div>
            </div>
            <div *ngIf="chat.allChatData.groups.rows.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMoreGroups()">
                <span *ngIf="!showMoreGroups">Show {{ chat.allChatData.groups.rows.length - SECTION_SOFT_MAX }} More</span>
                <span *ngIf="showMoreGroups">Show Less</span>
            </div>

            <div class="chat-row-header">
                <p>PEOPLE</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of getVisiblePeople()" class="chat-row people">
                <i class="fa fa-user user-icon" [class.active]="!!row.activeCount"></i>
                <p class="row-title">{{ row.title }}</p>
                <div *ngIf="!!row.unreadCount" class="notification-circle push-right">{{ row.unreadCount }}</div>
            </div>
            <div *ngIf="chat.allChatData.people.rows.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMorePeople()">
                <span *ngIf="!showMorePeople">Show {{ chat.allChatData.people.rows.length - SECTION_SOFT_MAX }} More</span>
                <span *ngIf="showMorePeople">Show Less</span>
            </div>

        </div>

        <div *ngIf="searchResults && !chat.errorLoading && !chat.isLoading" class="chat-body">
            <div *ngFor="let row of searchResults" class="chat-row recent">
                <span *ngIf="row.chatType === ChatRowType.GROUP || row.chatType === ChatRowType.BROADCAST" [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <i *ngIf="row.chatType === ChatRowType.PERSON" class="fa fa-user user-icon" [class.active]="!!row.activeCount"></i>
                <p (click)="selectRow(row)" class="row-title">{{ row.title }}</p>
            </div>
        </div>

    </div>

    <!-- Conversation view -->
    <div *ngIf="isOpen && isLoaded && openChat" class="chat-messages-view">
        <div class="chat-messages-header">
            <div class="chat-buttons">
                <i (click)="closeRow()" class="fa fa-chevron-left back-button"></i>
                <div *ngIf="chat.hasUnread" class="notification-circle">
                    {{ chat.getTotalUnread() }}
                </div>
            </div>
            <p class="messages-header-title">{{ openChat.title }}</p>
            <button [disabled]="callService.callState != CallState.INACTIVE" (click)="createCall()" id="call"><i class="fas fa-phone"></i></button>
        </div>
        <hr/>
        
        <div *ngIf="callService.activeCallId == openChat.id && callService.callState == CallState.CONNECTING" class="chat-main-container">
            <div class="connecting-container" >
                <div class="ball-scale-multiple">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <img src="../../../assets/profile-default.jpg" />
                <div class="status">Connecting</div>
                <div class="name">{{openChat.title}}</div>
            </div>
            <div class="controls-container">
                <button (disabled)="!this.callService.meetingSession" class="settings"><i class="fas fa-cog"></i></button>
                <button (click)="callService.endCall()" class="end"><i class="fas fa-phone"></i></button>
                <button (disabled)="!this.callService.meetingSession" class="unmute" *ngIf="callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone-slash"></i>
                </button>
                <button (disabled)="!this.callService.meetingSession" class="mute" *ngIf="!callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone"></i>
                </button>
            </div>
            
        </div>

        <div *ngIf="callService.activeCallId == openChat.id && callService.callState == CallState.CONNECTED" class="chat-main-container">
            <form *ngIf="callService.viewChangeDevices" [formGroup]="deviceForm" id="deviceSelectionForm" (ngSubmit)="submitDeviceForm(deviceForm.value)">
                <p>Select an output device:</p>
                <label class="item">
                    <input formControlName="audioOutputDevices" name="audioOutputDevices" checked type="radio" value="default">
                    <span class="checkmark"></span>
                    <div>{{callService.audioOutputDevices[0].label}}</div>
                </label>
               
                <label class="item">
                    <input formControlName="audioOutputDevices" name="audioOutputDevices"  type="radio" value="other">
                    <span class="checkmark"></span>
                    <div>Other:&nbsp;
                        <select [ngClass]="{'invalid': !outputDeviceValid}" formControlName="audioOutputDevicesOther" name="audioOutputDevicesOther">
                            <ng-container *ngFor="let device of callService.audioOutputDevices; let i = index" >
                                <option [value]="callService.audioOutputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                            </ng-container>  
                        </select>
                    </div>
                </label>

                <p>Select an input device:</p>
                <label class="item">
                    <input formControlName="audioInputDevices" name="audioInputDevices" checked type="radio" value="default">
                    <span class="checkmark"></span>
                    <div>{{callService.audioInputDevices[0].label}}</div>
                </label>
                
                <label class="item">
                    <input formControlName="audioInputDevices" name="audioInputDevices"  type="radio" value="other">
                    <span class="checkmark"></span>
                    <div>Other:&nbsp;
                        <select [ngClass]="{'invalid': !inputDeviceValid}" formControlName="audioInputDevicesOther" name="audioInputDevicesOther">
                            <ng-container *ngFor="let device of callService.audioInputDevices; let i = index" >
                                <option [value]="callService.audioInputDevices[i].deviceId" [attr.selected]="i==0 ? true : null">{{device.label}}</option>
                            </ng-container>
                           
                        </select>
                    </div>
                </label>
               

               
                <button class="join" type="submit">Save</button>
            </form>
            <div class="left" *ngIf="Object.keys(callService.presentAttendeeData).length == 1">
                {{openChat.title}} has left the call.
            </div>
            <div *ngIf="Object.keys(callService.presentAttendeeData).length <= 2" class="connected-container" >
                <ng-container *ngFor="let attendeeId of Object.keys(callService.presentAttendeeData)">
                    <div class="attendee" *ngIf="attendeeId != callService.me">
                        <div *ngIf="!callService.presentAttendeeData[attendeeId].muted" class="volume" [style.width.px]="125 + callService.presentAttendeeData[attendeeId].volume * 50" [style.height.px]="125 + callService.presentAttendeeData[attendeeId].volume * 50">
                        </div>
                        <img src="../../../assets/profile-default.jpg" />
                        <div class="status">{{callService.callTimeString}}</div>
                        <div class="name">{{openChat.title}}</div>
                    </div>
                </ng-container>
                
            </div>
            <div class="controls-container">
                <button (click)="toggleViewChangeDevices()" (disabled)="!this.callService.meetingSession" class="settings"><i class="fas fa-cog"></i></button>
                <button (click)="callService.endCall()" class="end"><i class="fas fa-phone"></i></button>
                <button (disabled)="!this.callService.meetingSession" class="unmute" *ngIf="callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone-slash"></i>
                </button>
                <button (disabled)="!this.callService.meetingSession" class="mute" *ngIf="!callService.isMuted()" (click)="callService.toggleMute()">
                    <i class="fas fa-microphone"></i>
                </button>
            </div>      
        </div>
       
            


        <div *ngIf="callService.activeCallId != openChat.id || callService.callState == CallState.INACTIVE" class="chat-main-container" [style.overflow]="isEmojiPickerShowing ? 'hidden' : 'auto'">
            <div *ngIf="isEmojiPickerShowing" class="emoji-picker-container">
                <!--<chat-emoji-picker (insertHandler)="emojiHandler($event)"></chat-emoji-picker>-->
                <div class="emoji-container">
                    <span 
                        *ngFor="let emojiOption of emojiShortlist" 
                        (click)="pickEmoji(emojiOption.emoji)" 
                        [class]="emojiOption.cssClass"
                    >
                        {{emojiOption.emoji}}
                    </span>
                </div>
            </div>
            <div class="chat-messages" #chatHistory>
                <div *ngFor="let message of openChat.chat" class="chat-message-row">
                    <chat-message [message]="message"></chat-message>
                    <button *ngIf="message.message.includes('meetingId')" (click)="joinCall(JSON.parse(message.message).meetingId)" class="join-meeting">Join</button>
                </div>
            </div>
        </div>
        <div *ngIf="callService.activeCallId != openChat.id || callService.callState == CallState.INACTIVE" class="chat-send-message">
            <div (click)="toggleEmojis()" class="emoji-button">
                <i class="fa fa-laugh fa-2x"></i>
            </div>
            <div class="message-input-container">
                <div class="input-container">
                    <textarea #textInput="cdkTextareaAutosize"
                              cdkTextareaAutosize [cdkAutosizeMinRows]="2" [cdkAutosizeMaxRows]="maxRows()"
                              class="message-text-area" placeholder="Send a message"
                              [formControl]="chatField" (keyup)="textAreaKeyUp($event)"
                              (input)="inputHandler()" (change)="changeHandler()"
                    ></textarea><!-- (keyup.enter)="sendMessage()" -->
                </div>
            </div>
            <div (click)="sendMessage()" class="send-button">
                <i class="fa fa-chevron-right"></i>
            </div>
        </div>
    </div>

    <!-- Closed "Scoring Chat" button -->
    <div *ngIf="!isOpen" (click)="toggleOpen()" class="open-chat-button">
        <p><tra [slug]="getChatLabel()"></tra></p>
        <div *ngIf="chat.hasUnread" class="notification-circle closed-notification">
            {{ chat.getTotalUnread() }}
        </div>
    </div>
</div>
