import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IContentElementTextLink, ScoringTypes } from '../models';
import { indexOf } from '../services/util';
import { HyperlinkService } from '../hyperlink.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-text-link',
  templateUrl: './element-render-text-link.component.html',
  styleUrls: ['./element-render-text-link.component.scss']
})
export class ElementRenderTextLinkComponent implements OnInit, OnChanges {

  @Input() element:IContentElementTextLink;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isHighContrast: boolean = false;

  textLinkCaption?: string;

  constructor(
    private hyperLinkService: HyperlinkService
    ) { }

  ngOnInit() {
    // console.log(this.element)
    this.textLinkCaption = this.getTextLinkCaption();
  }
 
  getTextLinkCaption = () => this.element.readingSelectionCaption ? this.element.readingSelectionCaption : this.element.caption;

  openLink(){
      const link = this.hyperLinkService.linkRequest.getValue();
      if ((link.readerId === this.element.readerId && link.readerId && this.element.readerElementId===link.readerElementId) 
            || (link.itemLabel === this.element.itemLabel && this.element.itemLabel)) {
        console.log("Empty")
        this.hyperLinkService.linkRequest.next({
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: undefined
        })
      } else if (this.element.itemLabel) {
        console.log("next item")
        this.hyperLinkService.linkRequest.next({
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: this.element.itemLabel
        })
      }  else {
        console.log("next canvas");
        this.hyperLinkService.linkRequest.next({
          readerElementId: this.element.readerElementId,
          readerId: this.element.readerId,
          itemLabel: undefined
        })
      }
  }

  ngOnChanges(changes:SimpleChanges){
    this.textLinkCaption = this.getTextLinkCaption();
  }


}
