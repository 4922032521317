
<ng-container *ngIf="isVisible()">
  <div style="padding:0.5em; background-color:#ccc;">
    <div style="padding:0.5em; border: 1px solid #ccc;">
      <element-render *ngFor="let contentElement of element.content"
      [contentElement]="contentElement" 
      [questionState]="questionState" 
      [isLocked]="isLocked"
      [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>
  <div style="padding:1em; line-height:1.3em">
    <markdown 
      class="markdown" 
      [data]="getResponse()" 
      [class.is-condensed]="true"
      ngPreserveWhitespaces
    ></markdown>
  </div>
</ng-container>