
<div 
    class="element-render-moveable-dnd"
    [class.has-frame]="element.hasFrame"
    [class.is-dragging]="isDragging"
    [style.width.em]="element.width"
    [style.height.em]="element.height"
    [ngStyle]="getStyle()"
>
    <render-image 
        *ngIf="element.backgroundImg" 
        [element]="element.backgroundImg"
    ></render-image>
    <div class="background-el">
        <div *ngFor="let image of this.element.moveableImages">
            <element-render *ngIf="image" class="moveable-obj" [style.left.em]="image.x" [style.top.em]="image.y" [contentElement]="image"></element-render>
        </div>
    </div>
    <div  cdkDropListGroup>
        <!-- (mousedown)="onEnter($event,target,cind)" -->
        <div *ngFor="let target of this.targets;let cind=index " tabindex="0" (keyup.enter)="onEnter($event,target,cind)"
            cdkDropList
            class="target-el"
            [cdkDropListData]="target.contents"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event); isDragging=false"
            (cdkDropListEntered)="isDragging=true"
            [ngStyle]="target.targetContext ? getTargetStyle(target) : {}"
        >
            <div class="hover-indicator"></div>
        <!-- (mousedown)="onEnter($event,target,cind)" -->
            <div cdkDrag class="drag-el" [class.is-deactivateDropShadow]="element.isHideDraggableDropShadow" [cdkDragDisabled]="isLocked" 
                (cdkDragStarted) = "dragStarted($event)"                
                (cdkDragMoved) = "dragging($event)"
                [class.is-transparent]="element.isDragsTransparent" 
                [style.width.em]="element.isDraggableNotResizable ? '' : target.targetContext.width" 
                [style.height.em]="element.isDraggableNotResizable ? '' : target.targetContext.height" 
                [style.background-color]="getDraggableColour(target.contents[0].ref)"
                *ngIf="target.contents[0] && target.contents[0].ref && target.contents[0].ref.element"
            >
                <element-render 
                    [class.is-selected]="isSelected(target)"
                    [contentElement]="target.contents[0].ref.element"
                    [questionPubSub]="questionPubSub"
                ></element-render>
                <div class="img-drag-shield"></div>
            </div>
        </div>
    </div>
</div>
