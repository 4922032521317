import { CustomInteractionType, getElementWeight, ElementType, intializeDefault, QuestionState, IContentElementGridFill, IEntryStateGridFill } from "../../../ui-testrunner/models";
import { QuestionPubSub } from "../../../ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from "pixi.js";
import { Direction, ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { AXIS, IGraphConfig, pixiGraph, PIXI_GRAPH_DEFAULTS } from "./util/pixi-graph";
import { drawRectangle } from "./util/drawShapes";

enum EDGES {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export class GridFill extends CustomInteractionCtrl {
  
  element: IContentElementGridFill;
  spriteLoader: SpriteLoader;
  container: PIXI.Container;
  grid: pixiGraph;
  currentObj: PIXI.Graphics;
  shape: PIXI.Graphics;
  // Border Lines (acts as Drag handle)
  topLine: PIXI.Graphics;
  rightLine: PIXI.Graphics;
  bottomLine: PIXI.Graphics;
  leftLine: PIXI.Graphics;

  topLineMin: number;
  leftLineMax: number;
  rightLineMin: number;
  bottomLineMax: number;
  value: number;
  isDragging:boolean;

  constructor(element: IContentElementGridFill, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, removeGraphic, textToSpeech) {
    super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);
    this.element = element;
    this.initializeDefaults();

    //Draw Graph;
    this.initGraph();
    this.loadAssets().then(this.init);
    this.render();
  }

  loadAssets(): Promise<PIXI.Loader> {
    let assets = [];
    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites();
  }

  init = () => {
    // Init shape
    this.shape = new PIXI.Graphics();
    this.grid.addChild(this.shape)

    this.initialShape()
    this.drawSquare();
    this.render();
  };

  initialShape(){
    let state = this.questionState[this.element.entryId]
    if(state && state.shapeBoundries){
      const {shapeBoundries} = state;
      this.drawTopLine(shapeBoundries.top.x, shapeBoundries.top.y, shapeBoundries.top.width, shapeBoundries.top.height);
      this.drawRightLine(shapeBoundries.right.x, shapeBoundries.right.y, shapeBoundries.right.width, shapeBoundries.right.height);
      this.drawBottomLine(shapeBoundries.bottom.x, shapeBoundries.bottom.y, shapeBoundries.bottom.width, shapeBoundries.bottom.height);
      this.drawLeftLine(shapeBoundries.left.x, shapeBoundries.left.y, shapeBoundries.left.width, shapeBoundries.left.height);
      this.setValue(this.getArea())
    } else {
      // Default from config
      const {defaultX, defaultY, defaultWidth, defaultHeight, defaultBorderThick} = this.element;
      let width = defaultWidth * this.grid.gridW;
      let height = defaultHeight * this.grid.gridH;
      let x = this.getDisplayCoords(AXIS.X, defaultX);
      let y = this.getDisplayCoords(AXIS.Y, defaultY);

      this.drawTopLine(x, y - height, width, defaultBorderThick);
      this.drawRightLine((x + width) - defaultBorderThick, y - height, defaultBorderThick, height);
      this.drawBottomLine(x, y - defaultBorderThick, width, defaultBorderThick);
      this.drawLeftLine(x, y - height, defaultBorderThick, height);
    }

  }

  getUpdatedState(): Partial<IEntryStateGridFill> {

    const weight = getElementWeight(this.element);
    const isCorrect = this._isCorrect();
    return {
      type: ElementType.CUSTOM_INTERACTION,
      subtype: CustomInteractionType.GRID_FILL,
      value: this.value,
      shapeBoundries: this.shapeBoundries,
      isStarted: this.value !== undefined,
      isFilled: this.value >= 0,
      isCorrect, 
      score: isCorrect ? weight : 0,
      weight: weight,
      scoring_type: ScoringTypes.AUTO
    };
  };

  _isCorrect(){
    let isCorrect = this.element.value == this.value;
    if(this.element.tolerance && !this.element.isSnapping) {
      let min = this.value - this.element.tolerance;
      let max = this.value + this.element.tolerance;
      isCorrect = this.value >= min && this.value <= max
    }
    return isCorrect;
  }

  setValue(val): void {
    this.value = val;
    this.updateState();
  }

  handleNewState(): void {}

  getArea(){
    let height = Math.abs(this.bottomLine.y + this.element.defaultBorderThick - this.topLine.y) / this.grid.gridH;
    let width = Math.abs(this.rightLine.x + this.element.defaultBorderThick - this.leftLine.x) / this.grid.gridW;
    // console.log(height, width,  height * width)
    return height * width;
  }

  initGraph = () => {
    const { canvasHeight } = this.element;
    let config = { ...PIXI_GRAPH_DEFAULTS, render: this.render, canvasHeight, lineColor: this.getColor(), isHiContrast: this.isHCMode() };
    
    for (let conf of Object.keys(config)){
      if(conf in this.element){
          config[conf] = this.element[conf];
      }
    }
    
    this.grid = new pixiGraph(<IGraphConfig>config);
    this.grid.interactive = true;
    this.grid.zIndex = 2;
    this.container.addChild(this.grid);
    // console.log(this.grid.xAxisData, this.grid.yAxisData)
  };

  initializeDefaults = () => {

    let defaults = {
      ...PIXI_GRAPH_DEFAULTS,
      defaultX: 1,
      defaultY: 1,
      defaultWidth: 1,
      defaultHeight: 1,
      defaultBorderThick: 1,
      isDefaultBorder: true,
      defaultBorderColor: '#00ff00',
      defaultFillColor: '#00ff00',
      defaultFillColorAlpha: 0.5
    }
  
    intializeDefault(defaults, this.element);
    this.spriteLoader = new SpriteLoader();
    this.isDragging = false;
    // Create playground
    this.container = new PIXI.Container();
    this.container.interactive = true;
    this.container.zIndex = 2;
    this.addGraphic(this.container);
  };

  drawLine(x, y, width, height) {
    const {defaultBorderColor, defaultFillColorAlpha} = this.element
    let rect = drawRectangle(0, 0, width, height, {
      width: 0,
      color: defaultBorderColor,
      alpha: defaultFillColorAlpha
    });
    rect.position.set(x, y);
    rect.interactive = true;
    rect.buttonMode = true;
    rect.zIndex = 99;
    return rect;
  }

  drawSquare() {
    // const thickness = this.element.defaultBorderThick;
    let color = parseInt(this.element.defaultFillColor.replace("#", ''), 16);
    if(this.shape) this.shape.clear();
    let x = this.topLine.x;
    let y = this.topLine.y;
    let width = this.rightLine.x - this.leftLine.x 
    let height = this.bottomLine.y - this.topLine.y
    const rect = new PIXI.Rectangle(x, y, width, height);
    this.shape.beginFill(color);
    this.shape.drawShape(rect);
    this.shape.endFill();
    this.shape.alpha = this.element.defaultFillColorAlpha;
    // this.shape.position.set(x, y)
  }

  drawLeftLine(x, y, width, height){
    // #TODO: Maybe use .clear() instead of removing and adding it back?
    this.grid.removeChild(this.leftLine);
    this.leftLine = this.drawLine(x, y, width, height);
    this.leftLine.name = EDGES.LEFT;
    this.registerMouseEvents(this.leftLine);
    this.grid.addChild(this.leftLine);
  }

  drawRightLine(x, y, width, height){
    this.grid.removeChild(this.rightLine);
    this.rightLine = this.drawLine(x, y, width, height);
    this.rightLine.name = EDGES.RIGHT;
    this.registerMouseEvents(this.rightLine);
    this.grid.addChild(this.rightLine);
  }

  drawTopLine(x, y, width, height){
    this.grid.removeChild(this.topLine);
    this.topLine = this.drawLine(x, y, width, height);
    this.topLine.name = EDGES.TOP;
    this.registerMouseEvents(this.topLine);
    this.grid.addChild(this.topLine);
  }

  drawBottomLine(x, y, width, height){
    this.grid.removeChild(this.bottomLine);
    this.bottomLine = this.drawLine(x, y, width, height);
    this.bottomLine.name = EDGES.BOTTOM;
    this.registerMouseEvents(this.bottomLine);
    this.grid.addChild(this.bottomLine);
  }

  updateVerticalBorder(){
    const {defaultBorderThick} = this.element
    this.drawTopLine(this.leftLine.x, this.topLine.y , (this.rightLine.x + defaultBorderThick) - this.leftLine.x, defaultBorderThick);
    this.drawBottomLine(this.leftLine.x, this.bottomLine.y , this.rightLine.x - this.leftLine.x + defaultBorderThick, defaultBorderThick);
  }

  updateHorizontalBorder(){
    const {defaultBorderThick} = this.element
    this.drawLeftLine(this.leftLine.x, this.topLine.y , defaultBorderThick, (this.bottomLine.y - this.topLine.y) + defaultBorderThick);
    this.drawRightLine(this.rightLine.x, this.topLine.y , defaultBorderThick, (this.bottomLine.y - this.topLine.y) + defaultBorderThick);
  }

  registerMouseEvents(obj) {
    obj.on("mousedown", $event => this.activateMouseDown($event, obj));
    obj.on("mouseup", $event => this.deactivateMouseDown($event));
    obj.on("mousemove", $event => this.changeLocation($event, obj));
    obj.on("mouseupoutside", $event => this.deactivateMouseDown($event));
  }

  activateMouseDown(event, obj) {
    this.isDragging = true;
    this.currentObj = obj;
  }

  deactivateMouseDown(evt) {
    this.stopDragging(evt);
  }

  changeLocation($event, obj) {
    if (!this.isDragging) return;
    const mousePos = $event.data.getLocalPosition(this.container) //this.getScaledMousePos($event.data);
    this.updateLocation(mousePos, false);
  }

  stopDragging(event){
    if(this.element.isSnapping){
      const mousePos = event.data.getLocalPosition(this.container);
      this.updateLocation(mousePos, true);
    }
    this.currentObj = undefined;
    this.isDragging = false;
    this.setValue(this.getArea());
  }

  updateLocation(mousePos, snap?:boolean){
    if(!this.currentObj || this.isLocked) return;
    const getPosition = (axis, axisData, currentPx) => {
      let displayPx = currentPx;
        if(snap){
          const {displayCoord} = this.grid.getSnappedAxisData(axis, axisData, currentPx);
          displayPx = displayCoord;
        }
        return displayPx;
    }
    switch(this.currentObj.name){
      case EDGES.TOP:
        if (this.isValidTopLine(mousePos)){
          let y = getPosition(AXIS.Y, this.grid.yAxisData ,mousePos.y)
          this.topLine.y = y;
          this.updateHorizontalBorder();        
        } 
        break;
      case EDGES.RIGHT:
        if (this.isValidRightLine(mousePos)){
          let x = getPosition(AXIS.X, this.grid.xAxisData ,mousePos.x);
          this.rightLine.x = x - this.element.defaultBorderThick;
          this.updateVerticalBorder();
        } 
        break;
      case EDGES.BOTTOM:
        if (this.isValidBottomLine(mousePos)){
          let y = getPosition(AXIS.Y, this.grid.yAxisData ,mousePos.y);
          this.bottomLine.y = y - this.element.defaultBorderThick;
          this.updateHorizontalBorder();
        } 
        break;
      case EDGES.LEFT:
        if (this.isValidLeftLine(mousePos)){
          let x = getPosition(AXIS.X, this.grid.xAxisData ,mousePos.x);
          this.leftLine.x = x ;
          this.updateVerticalBorder();
        } 
        break;    
      default:
        break; 
    }
    this.drawSquare();
    this.render();
  }

  isValidTopLine(mousePos){
    if(mousePos.y >= this.grid.yEnd){
      if(this.element.isSnapping)  return mousePos.y <= this.bottomLine.y  - this.grid.gridH;
      return mousePos.y <= this.bottomLine.y - (this.grid.gridH / 10);
    }
    return false;
  }

  isValidRightLine(mousePos){
    if(mousePos.x <= this.grid.xEnd){
      if(this.element.isSnapping) return mousePos.x >= this.leftLine.x + this.grid.gridW
      return mousePos.x >= this.leftLine.x + (this.grid.gridW / 10) ;
    }
    return false;
  }

  isValidBottomLine(mousePos){
    if(mousePos.y <= this.grid.yStart){
      if(this.element.isSnapping) return mousePos.y >= this.topLine.y + this.grid.gridH
      return mousePos.y >= this.topLine.y + (this.grid.gridH / 10);
    }
    return false;
  }

  isValidLeftLine(mousePos){
    if(mousePos.x >= this.grid.xStart){
      if(this.element.isSnapping) return mousePos.x <= this.rightLine.x - this.grid.gridW;
      return mousePos.x <= this.rightLine.x - (this.grid.gridW / 10);
    }
    return false;
  }

  getDisplayCoords(axis, coord){
    if(!coord) return 0;
    switch (axis) {
      case AXIS.X:  return this.grid.xAxisData.get(coord).displayCoord;
      case AXIS.Y:  return this.grid.yAxisData.get(coord).displayCoord;
      default:      return undefined; 
    }
  }

  get shapeBoundries(){
    if(!this.topLine || !this.rightLine || !this.leftLine || !this.bottomLine) return undefined;
    return {
      'top' : {
        x: this.topLine.x,
        y: this.topLine.y,
        width: this.topLine.width,
        height: this.topLine.height
      },
      'left' : {
        x: this.leftLine.x,
        y: this.leftLine.y,
        width: this.leftLine.width,
        height: this.leftLine.height
      },
      'right' : {
        x: this.rightLine.x,
        y: this.rightLine.y,
        width: this.rightLine.width,
        height: this.rightLine.height
      },
      'bottom' : {
        x: this.bottomLine.x,
        y: this.bottomLine.y,
        width: this.bottomLine.width,
        height: this.bottomLine.height
      }
    }
  }

}

