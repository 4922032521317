import { CustomInteractionType, getElementWeight, ElementType, IContentElementScaleRadius, IEntryStateScaleRadius, QuestionState } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "src/app/ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";

export class ScaleRadiusCtrl extends CustomInteractionCtrl {

    element: IContentElementScaleRadius;
    spriteLoader: SpriteLoader
    radius: number;
    circleGr : PIXI.Graphics;
    cursorGr : PIXI.Graphics;
    canvasGr : PIXI.Graphics;
    cursorX: number;
    cursorY: number;
    initX: number;
    initY: number;
    maxRadius: number;
    image: PIXI.Sprite;
    userInteracted: boolean = false;
    userScaledRadius: boolean = false;

    constructor(element: IContentElementScaleRadius, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);
        this.element = element;
        this.spriteLoader = new SpriteLoader()
        if(this.element.scale == null) {
            this.element.scale = 1;
        }
        this.maxRadius = this.element.canvasWidth/2;
        if (this.maxRadius>this.element.canvasHeight/2) {
            this.maxRadius=this.element.canvasHeight/2
        }
        if (this.questionState && this.questionState[this.element.entryId]) {
            const state = this.questionState[this.element.entryId]
            if (state['radius'] ) {
                this.radius = state['radius']
            }

            if (state['xCursor'] ) {
                this.cursorX = state['xCursor']
            }

            if (state['yCursor'] ) {
                this.cursorY = state['yCursor']
            }
            this.userInteracted = state.userInteracted;
            this.userScaledRadius = state.userScaledRadius;
        }

        if (!this.radius) {
            this.radius = (this.maxRadius/2)*this.element.scale;
        }

        if (!this.cursorY) {
            this.cursorY = this.radius/this.element.scale;
        }

        if (!this.cursorX) {
            this.cursorX = this.radius/this.element.scale;
        }

        this.initX = this.maxRadius;
        this.initY = this.maxRadius;
        
        this.canvasGr = new PIXI.Graphics();
        this.canvasGr.interactive = true;
        this.registerMouseEvents(this.canvasGr)
        this.addGraphic(this.canvasGr)
        this.drawCanvas();

        this.circleGr = new PIXI.Graphics();
        this.circleGr.interactive = true;
        this.registerMouseEvents(this.circleGr)

        this.cursorGr = new PIXI.Graphics();
        this.cursorGr.interactive = true;
        this.registerMouseEvents(this.cursorGr)     
        
        this.loadAssets().then(this.init)
        this.render();
    }

    init = ({resources}) => {
        this.throttled_draw_cursor();
        this.addGraphic(this.cursorGr);        
        this.throttled_draw_circle();    
        this.addGraphic(this.circleGr);
        this.render();
    }

    loadAssets = () => {
        let assets = []
        if (this.element && this.element.img && this.element.img.url) {
            assets.push({name: "circle", path: this.element.img.url})
        }
        this.spriteLoader.addSpritestoLoader(assets)
        return this.spriteLoader.loadSprites()
    }

    getUpdatedState(): Partial<IEntryStateScaleRadius> {
        
        const weight = getElementWeight(this.element)
        const score = this.element.answer == this.radius ? weight : 0
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.SLIDER,
            radius: this.radius,
            xCursor: this.cursorX,
            yCursor: this.cursorY,
            userInteracted: this.userInteracted,
            userScaledRadius: this.userScaledRadius,
            isStarted: this.userInteracted,
            isFilled: this.userScaledRadius,
            isCorrect: score==weight,
            score: score,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        }
    }

    registerMouseEvents(obj:PIXI.Graphics) {
        obj.on('mousedown', ($event) => {this.activateMouseDown()});
        obj.on('mouseup', ($event) => {this.deactivateMouseDown()});
        obj.on('mousemove', ($event) => {
            if (this.isMouseDown) {
                this.userScaledRadius = true
                this.changeCircleSize($event)
            }
        });
    }

    handleNewState(): void {
        let qsState = this.questionState[this.element.entryId]
        this.setRadius(qsState.radius/this.element.scale, this.cursorX, this.cursorY);
    }

    isMouseDown;

    activateMouseDown() {
        this.isMouseDown = true;
        this.userInteracted = true;
        this.updateState()
    }

    deactivateMouseDown() {
        this.isMouseDown = false;
    }

    changeCircleSize($event) {
        if (this.isLocked) return;
        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom
        const distX = x-this.initX;
        const distY = y-this.initY;

        this.setRadius(Math.sqrt(distX*distX + distY*distY), x, y)
    }

    setRadius(r: number, x: number, y: number) {
        this.radius = r*this.element.scale;
        if (this.radius > this.maxRadius*this.element.scale) {
            this.radius = this.maxRadius*this.element.scale
        }
        if (this.element.decimalRound) {
            this.radius = parseFloat(this.radius.toFixed((this.element.decimalRound)))
        } else {
            this.radius = parseInt(this.radius.toFixed(0));
        }
        this.cursorX = x;
        this.cursorY = y;
        this.throttled_draw_circle();
        this.throttled_draw_cursor();
        this.updateState();
        this.render()
    }

    drawCanvas() {
        const width = this.maxRadius*2;
        const canvas = new PIXI.Rectangle(0, 0, width, width);
        this.canvasGr.beginFill(0xFFFFFF);
        this.canvasGr.drawShape(canvas)
        this.canvasGr.endFill()
    }

    throttle_time = 100

    throttled_draw_circle = _.throttle(()=> {
        this.circleGr.clear();
        this.circleGr.beginFill(0xffffff);
        const sRadius = this.radius/this.element.scale;
        this.circleGr.drawCircle(this.initX, this.initY, sRadius);
        this.image = PIXI.Sprite.from(this.spriteLoader.loader.resources['circle'].texture) 
        this.addGraphic(this.image);
        if (this.image) {
            this.image.mask = this.circleGr;
            this.image.height = sRadius*2;
            this.image.width = sRadius*2;
            this.image.x = this.initX-sRadius
            this.image.y = this.initY-sRadius
            this.image.zIndex = 7
        }
        this.circleGr.endFill();
        this.circleGr.zIndex = 8
    }, this.throttle_time)

    throttled_draw_cursor = _.throttle(()=> {
        const cursor = new PIXI.Circle(this.cursorX, this.cursorY, 10);
        this.cursorGr.clear();
        this.cursorGr.beginFill(0x5999FF);
        this.cursorGr.lineStyle(2, 0x5999FF).moveTo(this.initX, this.initY).lineTo(this.cursorX, this.cursorY);
        this.cursorGr.drawShape(cursor);
        this.cursorGr.endFill();
        const sRadius = this.radius/this.element.scale;
        this.cursorGr.drawCircle(this.initX, this.initY, sRadius+1);
        this.cursorGr.zIndex = 10

    }, this.throttle_time)

}