import { Injectable } from '@angular/core';
import { IUserInfo, AuthService } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { G9DemoDataService } from './g9-demo-data.service';
import {AccountType} from "../constants/account-types";
import { boardTechReadinessCheck, techReadiGoMode, techReadiGoModeOsslt, boardTechReadinessCheckOsslt } from './sa-tech-readiness/data/checklist';
import { IFilterToggle } from '../ui-partial/filter-toggles/filter-toggles.component';

const SCHOOL_ADMIN_ROLE = 'schl_admin';

export type GroupRolesList = Array<{
  role_type:string,
  group_id:number,
}>;

export enum ClassFilterId {
  G9 = 'G9',
  OSSLT = 'OSSLT',
}

@Injectable({
  providedIn: 'root'
})
export class MySchoolService {

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private g9DemoData: G9DemoDataService, // temp
  ) { 
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      if(v !== null) {
        // temp change to avoid API error
        if ([AccountType.BC_GRAD_MINISTRY_ADMIN, AccountType.BC_FSA_MINISTRY_ADMIN].includes(this._userInfo.accountType)) {
          console.log('return nothing');
          return;          
        }
        this.loadMyRoles();
      }
    });
  }

  // vars (these should never be public)
  private _userInfo:IUserInfo;
  private _roles:GroupRolesList;
  private info: BehaviorSubject<GroupRolesList> = new BehaviorSubject(null);
  fail: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private currentSchoolGroupId:number;

  private classFilterToggles:IFilterToggle[] = [
    {
      id: ClassFilterId.G9,
      caption: 'txt_g9_math',
      value: false,
    },
    {
      id: ClassFilterId.OSSLT,
      caption: 'lbl_osslt',
      value: false,
    },
  ]

  // public methods
  public sub() {
    return this.info;
  }

  private hasSchoolAdminRole(roles: GroupRolesList) {
    return roles.find(role => ['schl_admin', 'bc_fsa_admin'].includes(role.role_type))
  }
  
  private loadMyRoles(){
    return this.auth
      .apiFind(this.routes.SCHOOL_ADMIN_ROLES)
      .then((roles:GroupRolesList) => {
        this._roles = roles;
        if (roles.length > 0 && this.hasSchoolAdminRole(roles)){
          const activeRole = roles[0]; // arbitrary... assumption is that there is not more than one school per school admin right now
          this.currentSchoolGroupId = activeRole.group_id;
          return this.auth
              .apiFind(this.routes.SCHOOL_ADMIN_SCHOOL, {
                query: {
                  group_id: this.currentSchoolGroupId,
                }
              })
        }
        else {
          this.fail.next(true);
        }
      })
      .then(schoolsData => {
        if (schoolsData) {
          this.g9DemoData.init(schoolsData[0]); // always just one school from this request
          this.info.next(this._roles);
        }
      })
  }

  getCurrentSchoolGroupId() {
    return this.currentSchoolGroupId;
  }

  fetchSecureLink(route:string){
    return this.auth.apiGet(route, this.currentSchoolGroupId,this.configureQueryParams())
  }
  configureQueryParams(){
    const schoolData: any = this.g9DemoData.schoolData
    if (schoolData){
      return {
        query: {
          schl_group_id: schoolData.group_id,
        }
      }
    }
    return null;
  }
  loadChecklistStates(){
    let checklistPromise;
    if (this.currentSchoolGroupId){
      return Promise.resolve({});
      return this.auth
      .apiGet(
        this.routes.SCHOOL_ADMIN_CHECKLIST,
        this.currentSchoolGroupId,
        this.configureQueryParams()
      )
      .then( (checklistStates: any) => {
        const state = {};
        state[boardTechReadinessCheck] = checklistStates.boardTechReadiCompleted;
        state[boardTechReadinessCheckOsslt] = checklistStates.boardOssltTechReadiCompleted;
        state[techReadiGoMode] = checklistStates.warnTechReadiCompleted
        state[techReadiGoModeOsslt] = checklistStates.warnOssltTechReadiCompleted;
        checklistStates.checklistItems.forEach(checkItem => {
          state[checkItem.slug] = checkItem.value;
        })
        // console.log('checklist state raw', checklistStates)
        // console.log('checklist state', JSON.parse(JSON.stringify(state)))
        return state;
      });
     
    }

   return this.loadMyRoles().then( roles => {
     return Promise.resolve({});
     return  this.auth
     .apiGet(
       this.routes.SCHOOL_ADMIN_CHECKLIST,
       this.currentSchoolGroupId,
       this.configureQueryParams()
     )
     .then( (checklistStates: any) => {
       const state = {};
       checklistStates.forEach(checkItem => {
         state[checkItem.slug] = checkItem.value;
         state[boardTechReadinessCheck] = checkItem.boardTechReadiCompleted;
         state[techReadiGoMode] = checkItem.warnTechReadiCompleted;
       })
       return state;
     });
    })  
  }




  toggleTRChecklist(slug:string, value:boolean){
    return this.auth
      .apiUpdate(
        this.routes.SCHOOL_ADMIN_CHECKLIST,
        this.currentSchoolGroupId,
        {
          slug,
          value,
        },
        this.configureQueryParams()
      )
  }

  getClassFilterToggles(){
    return this.classFilterToggles;
  }

  // to do
  constructPermissionsParams(query?:any){
    // if (!this._info){ console.warn('myInst constructPermissionsParams early exit'); return; }
    const instit_group_id = 0; // this._info.groupId;
    return {
      query:{ 
        ... query,
        instit_group_id, 
      }
    }
  }



}
