import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { EditingDisabledService } from 'src/app/ui-item-maker/editing-disabled.service';
import { StyleprofileService, IStyleProfile } from 'src/app/core/styleprofile.service';
import { LangService } from 'src/app/core/lang.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'capture-math',
  templateUrl: './capture-math.component.html',
  styleUrls: ['./capture-math.component.scss']
})
export class CaptureMathComponent implements OnInit, OnDestroy, OnChanges {

  @Input() obj:any;
  @Input() prop:string;
  @Input() startBlank:boolean;
  @Input() isManualKeyboard:boolean;
  @Input() isSpacesTabs:boolean;
  @Input() isLocked:boolean;
  @Input() latexValue:string;
  @Input() control: FormControl;
  @Output() onChange = new EventEmitter<null>();
  @ViewChild('mathField', { static: true }) mathFieldRef: ElementRef;

  MathLive = null;
  mathField;
  isMathFieldLocked:boolean = false;

  constructor(private editingDisabled: EditingDisabledService,
    private profile: StyleprofileService, 
    private lang: LangService) { }

  ngOnInit() {
    this.clearPreviousKeyboards();
  }

  ngOnDestroy(){
    
  }

  clearPreviousKeyboards(){
    const lingeringKeyboards = document.getElementsByClassName('ML__keyboard');
    if (lingeringKeyboards){
      // console.log('on destroy :: ', lingeringKeyboards.length);
      for (let i=0; i<lingeringKeyboards.length; i++){
        const el = lingeringKeyboards[i];  
        el.parentElement.removeChild(el)
      }
    }
  }

  ngOnChanges(){
    if(this.isLocked && !this.isMathFieldLocked){
      this.isMathFieldLocked = true;
      let currentLatex = this.latexValue !== undefined ? this.latexValue : this.obj[this.prop];
      let staticMathFieldContainer = this.mathFieldRef.nativeElement;
      if (!currentLatex) currentLatex='';
      staticMathFieldContainer.innerHTML = '$$'+currentLatex+'$$';
      this.MathLive.renderMathInElement(staticMathFieldContainer);
    }
  }

  ngAfterViewInit() {
    const language = this.lang.c();
    const styleProfile = this.profile.getStyleProfile()[language].configuration.math;
    // console.log('mathFieldRef', this.mathFieldRef.nativeElement)
    this.MathLive = (window as any).MathLive;
    if (this.isMathFieldLocked) return;
    const mathField =  this.MathLive.makeMathField(this.mathFieldRef.nativeElement, {
      smartMode: false,
      smartFence: styleProfile.smartFence,
      ignoreSpacebarInMathMode: false, 
      virtualKeyboards: 'numeric',
      virtualKeyboardMode: this.isManualKeyboard ? 'manual' : 'onfocus',
      onKeystroke: (mf:any, keystroke:string, ev:{key:string, type:string}) => {
        if (ev.type === 'keydown'){
          // If we do not force these in, they do not appear.
          switch (ev.key){
            case '%':
            case '$':
              mf.$insert('\\'+ev.key);
              // mf.$insert(ev.key);
          }
        }
        return true;
      },
      onContentDidChange: mf => {
        let newLatex:string = mathField.$latex();
        let newLatexClean = newLatex.replace('#@', '\\placeholder{}');
        newLatexClean = newLatexClean.replace('\\imaginaryI', 'i')
        newLatexClean = newLatexClean.replace('\\exponentialE', 'e')

        if (newLatex !== newLatexClean){
          newLatex = newLatexClean;
          mathField.$latex(newLatexClean);
        }
        if (this.obj){
          if (this.prop && newLatex !== this.obj[this.prop]){
            this.obj[this.prop] = newLatex;
          }
          if (this.latexValue !== undefined) {
            this.control.setValue(newLatex);
            this.latexValue = newLatex;
          }
          this.onChange.emit();
          if (!this.obj._changeCounter){ this.obj._changeCounter = 1; }
          else{ this.obj._changeCounter ++; }
        }
      }
    });
    if (this.prop && this.obj[this.prop]){
      this.obj[this.prop] = mathField.$latex(this.obj[this.prop])
    }
    if (this.latexValue !== undefined) {
      this.latexValue = mathField.$latex(this.latexValue)
    }
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
