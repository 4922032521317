import { Component, Input, OnInit } from "@angular/core";
import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { ElementType, ElementTypeDefs, getElementWeight, IContentElementValidator, IEntryStateValidator, ScoringTypes, IValidatorCombinationProp, ValidatorMode, IEntryStateScored } from "../models";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";

@Component({
  selector: "element-render-validator",
  templateUrl: "./element-render-validator.component.html",
  styleUrls: ["./element-render-validator.component.scss"]
})
export class ElementRenderValidatorComponent implements OnInit {
  @Input() element: IContentElementValidator;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() questionPubSub?: QuestionPubSub;

  combinationMap: Map<string, IValidatorCombinationProp>;
  combinationValidateId: Map<number, { isFilled: boolean }>;

  constructor() {}

  ngOnInit(): void {
    if (this.isComboMode()) {
      this.ensureState();
      this.initComboMode();
    }
    this.questionPubSub.allSub().subscribe(payload => {
      if (this.isSingleMode()) {
        if (payload.entryId === this.element.validateId && payload.type === PubSubTypes.UPDATE_VALIDATOR) {
          this.updateState();
        }
      } else {
        if (this.combinationValidateId.has(payload.entryId) && payload.type === PubSubTypes.UPDATE_VALIDATOR) {
          this.updateStateCombo();
        }
      }
    });
  }

  ngOnChange() {
    if (this.isComboMode()) {
      this.initComboMode();
    }
  }

  updateState() {
    const value = this.questionState[this.element.validateId][this.element.validateProp];
    const isFilled = value != null;
    const isCorrect = isFilled && value == this.element.correctValue;
    const weight = getElementWeight(this.element);
    const score = isCorrect ? weight : 0;

    // console.log("Value " + value);
    // console.log("isCorrect " + isCorrect);
    // console.log("Correct Value " + this.element.correctValue);
    const es: IEntryStateValidator = {
      type: ElementType.VALIDATOR,
      [this.element.validateProp as string] : value,
      isCorrect,
      isFilled,
      isStarted: isFilled,
      weight,
      score,
      scoring_type: ScoringTypes.AUTO
    };

    this.questionState[this.element.validateId] = es;
  }

  updateStateCombo() {
    let isCorrect,
    isFilled = false;
    const weight = getElementWeight(this.element);

    for (let combination of this.element.combinations) {
      isCorrect = false;
      for (let element of combination) {
        const { validateId, elementType, correctValue } = element;
        const value = this.getElementData(elementType, this.questionState[validateId]);
        // console.log("Value " ,typeof value, value);

        if (!isFilled) isFilled = value != null;
        this.combinationValidateId.set(validateId, { isFilled: value != null });
        
        if (Array.isArray(value)){
          const ansKey = new Set(correctValue.trim().split(','))
          for(let val of value){
            isCorrect = isFilled && ansKey.has(val)
            if(!isCorrect) break;
          }
        } 
        else { isCorrect = isFilled && value == correctValue }
        
        if (!isCorrect) break;        
      }
      if (isCorrect) break;
    }

    // console.log("isCorrect", isCorrect)
    let entryState: IEntryStateValidator = {
      type: ElementType.VALIDATOR,
      value: undefined,
      isCorrect: isCorrect,
      isStarted: true,
      isFilled: isFilled,
      score: isCorrect ? weight : 0,
      weight: weight,
      scoring_type: ScoringTypes.AUTO
    };

    //update iscorrect, isFilled, isStarted for the elements
    this.combinationValidateId.forEach((elementState, id) => {
      const questionState = this.questionState[id];
      if (questionState) {
        questionState.isCorrect = isCorrect;
        questionState.isStarted = true;
        // Only override isFilled when it's true otherwise go with original questionState
        if(elementState.isFilled){ 
          questionState.isFilled = elementState.isFilled;
        }
      }
    });

    this.questionState[this.element.entryId] = entryState;
  }

  ensureState() {
    let entryState: IEntryStateValidator;
    if (this.questionState) {
      const entryId = this.element.entryId;
      entryState = this.questionState[entryId];
      if (!entryState) {
        entryState = {
          type: ElementType.VALIDATOR,
          value: null,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO
        };
        this.questionState[entryId] = entryState;
      }
    }
  }

  isSingleMode() {
    return this.element.mode == ValidatorMode.NORMAL;
  }

  isComboMode() {
    return this.element.mode == ValidatorMode.COMBINATION;
  }

  initComboMode() {
    this.combinationValidateId = new Map();
    this.element.combinations[0].forEach(element => {
      if (!this.combinationValidateId.has(element.validateId)) {
        this.combinationValidateId.set(element.validateId, { isFilled: false });
      }
    });
  }

  getElementData(elementType, questionState) {
    switch (elementType) {
      case ElementType.MCQ:
        if (!questionState.selections.length) return null;
        let selection : string | string[];
        if(questionState.selections.length > 1){
          selection = questionState.selections.map(sel => 'ABCDEFGHIJKLMNOP'[sel.i]);
        } else {
          let selectionIdx = questionState.selections[0].i
          selection = 'ABCDEFGHIJKLMNOP'[selectionIdx];
        }
        return selection;
      case ElementType.CUSTOM_INTERACTION:
      case ElementType.FRAME:
      case ElementType.GROUPING:
      case ElementType.INPUT:
        switch (questionState.type) {
          case 'input-number': return questionState.value
          // case 'input-fraction' : return 
        }

      default:
        return null;
    }
  }
}
