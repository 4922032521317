import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { IContentElementImage, QuestionState, ElementType, IEntryStateVideo, IContentElementAudio, ToolTipPositions, DisplayMode } from "../models";
import { Subject } from "rxjs";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import { LangService } from "../../core/lang.service";
import { RenderAudioComponent } from "../render-audio/render-audio.component";
import { HttpClient } from '@angular/common/http';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

import { TextToSpeechService } from "../text-to-speech.service";

@Component({
  selector: "element-render-audio",
  templateUrl: "./element-render-audio.component.html",
  styleUrls: ["./element-render-audio.component.scss"]
})
export class ElementRenderAudioComponent implements OnInit, OnDestroy {
  @Input() element: IContentElementAudio;
  @Input() isLocked: boolean;
  @Input() isShowSolution: boolean;
  @Input() questionState: QuestionState; // not used
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowTranscripts: boolean = false;
  @Input() allowPlaybackSpeed: boolean = false;

  @ViewChild(RenderAudioComponent) audioPlayer: RenderAudioComponent;

  constructor(private changeDetector: ChangeDetectorRef, private auth: AuthService,
    private routes: RoutesService, 
    public lang: LangService, private http: HttpClient, private textToSpeech:TextToSpeechService) {}

  playBackRate = 1.0
  playBackRates = [
    1.0, 0.9, 0.75, 0.5, 0.25
  ]

  trigger: Subject<boolean> = new Subject();
  ToolTipPositions = ToolTipPositions;

  isAutoPlay;
  transcriptUrl
  ngOnInit() {
    if (this.element.assetId) {
      this.auth.apiGet(this.routes.TEST_AUTH_ASSET, this.element.assetId).then((res)=>{
        if (!res) {
          return
        }
        if (this.lang.getCurrentLanguage()=='en' && res["transcript_en"]) {
          this.transcriptUrl = res["transcript_en"]
        } else if (this.lang.getCurrentLanguage()=='fr' && res["transcript_fr"]) {
          this.transcriptUrl = res["transcript_fr"]
        }
      })
    } else {
      this.transcriptUrl = this.element.transcriptUrl
    }
    this.isPlaying = false;
    if (this.element.isAutoPlay) {
      this.initAudioCountdown();
    }
  }

  ngAfterViewInit() {}

  hasCheckedCanStart;
  canStartPlaying() {
    if (!this.hasCheckedCanStart && this.questionState && this.canPlay()) {
      const id = this.element.entryId;
      const el = this.getAudioElement();
      //console.log("checking for timestamp");
      if (this.questionState && this.questionState[id]) {
        const ts = this.questionState[id].timeStamp;
        //console.log("timestamp", ts);
        if (!el) return;
        if (ts && ts > 0 && ts < el["duration"]) {
          this.isAutoPlay = true;
          //console.log("setting current time", ts);
          el["currentTime"] = ts;
          //console.log("timestamp", el["currentTime"]);
          el.play();
          //console.log("timestamp", el["currentTime"]);
        }
      }
      this.hasCheckedCanStart = true;
    }
  }

  bufferVal = 5;
  isPlaying = false;
  ngOnDestroy() {
    this.isCountdownInProgress = false;
    const id = this.element.entryId;
    if (id && this.questionState && this.questionState[id]) {
      const qState = this.questionState[id];
    }
    try {
      const el = this.getAudioElement();
      // console.log('el to destroy', el)
      el.pause();
    } catch (e) {}
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state: IEntryStateVideo = {
        isStarted: true,
        isFilled: false,
        numViews: 0,
        timeStamp: 0,
        type: ElementType.AUDIO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      return this.questionState[this.element.entryId];
    }
    else{
      return {};
    }
  }

  private isSafari() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }

  isCountdownInProgress: boolean;
  countdownInterval: any;
  countdownTimeRemaining: number;
  initAudioCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.isCountdownInProgress = true;
    this.countdownTimeRemaining = this.element.autoPlayDelay;
    this.countdownInterval = setInterval(() => {
      if (!this.isCountdownInProgress) {
        return;
      }
      if (this.countdownTimeRemaining > 0) {
        this.countdownTimeRemaining--;
      }
      if (this.countdownTimeRemaining <= 0) {
        this.isCountdownInProgress = false;
        // play audio
        this.trigger.next(true);
      }
    }, 1000);
  }

  hasControls() {
    if (this.element.isHidden) {
      return false;
    }
    return true;
  }

  onPlay() {
    console.log("on play");
    this.addView();
  }

  play() {
    this.isCountdownInProgress = false;
    this.isPlaying = true;
    console.log("in play");
  }

  notPlaying() {
    this.isPlaying = false;
    this.isAutoPlay = false;
  }

  addView() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId];
      if (!this.isAutoPlay) {
        this.isAutoPlay = true;
        state.numViews++;
        state.timeStamp = 0;
        this.resetFlag();
      }
    }
  }

  isEnabled() {
    const state = this.ensureState();
    const el = this.getAudioElement();
    if (!el) {
      return false;
    }
    if (state) {
      if (this.element.isLimitAudioPlays && (state["timeStamp"] == 0 || state["timeStamp"].toFixed(3) == el["duration"].toFixed(3)) && state.numViews >= this.element.numAudioPlays) {
        return false;
      }
      return true;
    }
    return false;
  }

  playEnd() {
    this.onAudioEnd();
    this.notPlaying();
  }

  onAudioEnd() {
    if (this.element.onAudioEnd) {
      this.element.onAudioEnd.forEach(pub => {
        this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
      });
    }
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId];
      state.isFilled = true
    }
  }

  adjustRate() {
    const el = this.getAudioElement()
    if (el) {
      el.playbackRate = this.playBackRate
    }
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  getNumViews() {
    if (this.questionState && this.questionState[this.element.entryId] && this.questionState[this.element.entryId].numViews) {
      return this.questionState[this.element.entryId].numViews;
    }
    return 0;
  }

  canPlay() {
    return this.isEnabled();
  }

  getAudioElement() {
    const id = this.element.entryId;
    let el = null;

    if (this.audioPlayer && this.audioPlayer.audioPlayer) {
      el = this.audioPlayer.audioPlayer.nativeElement;
    }

    return el;
  }

  getStartTime() {
    if (!this.questionState || !this.questionState[this.element.entryId]) {
      return 0;
    }
    return this.questionState[this.element.entryId].timeStamp;
  }

  getSecRemainingStr() {
    const element = this.getAudioElement();
    if (!element) {
      return "";
    }

    const leadingZero = (n: number) => {
      if (("" + n).length < 2) {
        return "0" + n;
      }
      return "" + n;
    };
    const convertSecToMinSec = sec => {
      if (!sec) {
        return "00:00";
      }
      const min = Math.floor(sec / 60);
      return leadingZero(min) + ":" + leadingZero(Math.floor(sec - min * 60));
    };
    return convertSecToMinSec(element["currentTime"]) + " / " + convertSecToMinSec(element["duration"]);
  }

  timeSet = false;
  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getAudioElement();
      if (!element) return;
      //console.log("timestamp update", element["currentTime"])
      const state = this.questionState[this.element.entryId];
      //This was added as a special case for safari on ipad
      if (state.timeStamp && state.timeStamp > element["currentTime"] && !this.timeSet) {
        element["currentTime"] = state.timeStamp;
        this.timeSet = true;
      } else {
        state.timeStamp = element["currentTime"];
      }
    }
  }

  isCustomMode() {
    return !this.element.displayMode || this.element.displayMode === DisplayMode.CUSTOM
  }

  isNormalMode() {
    return this.element.displayMode === DisplayMode.NORMAL
  }

  transcriptOn = false;
  transcript = "";
  showTranscript() {
    if (this.transcriptUrl) {
      this.transcriptOn = !this.transcriptOn;
    } 

    this.http.get(this.transcriptUrl).subscribe(res => {
      if (!res["text"]) return
      this.transcript = res["text"]
    });
  }
  
  getHCStyle(){
    if(this.textToSpeech.isHiContrast){
      return {
        'background-color' : '#000',
        'color' : '#fff',
        'border' : '1px solid white'
      }
    }
    return  {}
  }

  getRemainingPlayStyle(){
    let style = {}
    style['background-color'] = '#fff'
    style['padding'] = '0.5em'
    style['line-height'] = '1.1em'
    style = Object.assign(style, this.getHCStyle())
    return style
  }

  getRemainingSecStyle(){
    let style = {}
    style['background-color'] = '#f1f3f0'
    style['padding'] = '0.5em'
    style['font-size'] = '0.94em'
    style['white-space'] = 'nowrap'
    style = Object.assign(style, this.getHCStyle())
    return style
  }

  getNumPlaysRemainingSlug(){
    return this.element.numAudioPlays- this.getNumViews() > 1 ? 'lbl_num_plays_remaining' : 'lbl_num_play_remaining';
  }
}
