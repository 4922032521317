import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountType } from '../constants/account-types';
import { API_ADDRESS_QC1 } from './api-addr/l';
import { HttpClient } from '@angular/common/http';
import { WhitelabelService } from '../domain/whitelabel.service';
import Sockette from 'sockette';

const auth = require('@feathersjs/authentication-client');

export const F_ERR_MSG__FAILED_TO_FETCH = 'Failed to fetch'; // if feather is unable to connect to the server
export const F_ERR_MSG__INVALID_LOGIN = 'Invalid login';
export const F_ERR_MSG__REAUTH_NO_TOKEN = 'No accessToken found in storage';
export const F_ERR_MSG__RELOGIN = 'jwt expired';

const TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;

interface IAuthRes {
  accessToken: string,
  user?: IUserInfoCore,
  authentication?: {
    payload: IUserInfoCore
  }
}
interface IApiFindQuery {
  query?: {
    $limit?: number,
    [key: string]: any;
  }
  [key: string]: any;
}
interface IUploadResponse {
  success: boolean,
  filePath?: string,
  url?: string,
}
export interface IUserInfoCore {
  email: string,
  uid: number,
  accountType: AccountType,
  accountId: number,
  accountInfo: {
    institutionId?: number // test admins only
  },
  // roles?: string[],
  firstName: string,
  lastName: string,
  imgURL?: string
  showComments?: boolean;
}

export interface IUserInfo extends IUserInfoCore {
  accessToken: string,
  lang?: string,
  dashboardType?: string
}

export const DB_TIMEZONE = 'Z';

export const getFrontendDomain = () => {
  return window.location.origin + '/';
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private api = <any>feathers();
  private restClient = <any>rest(this.whitelabel.getApiAddress());
  private _user: IUserInfo;
  public userSub: BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  private reauthCompletedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false); // gets overridden upon registration
  private apiAuthExpire: Subject<boolean> = new Subject(); // if you want to use BehaviorSubject, need to be diligent about setting this to false whenever the user is re-authenticated
  public isLoggingIn: boolean;

  public activeKioskPassword: string; // unclean


  authWebSocket: any;
  private readonly authWebSocketURI: string = 'wss://mnomt58qw8.execute-api.ca-central-1.amazonaws.com/production';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private whitelabel: WhitelabelService,
  ) {
    this.api.configure(this.restClient.fetch(window['fetch']));
    this.api.configure(auth({ storage: window['localStorage'] }));
    this.reauth();
    // setInterval(this.refreshToken, TOKEN_REFRESH_INTERVAL);
    // this.clearLocalSession()
  }

  ngOnDestroy() {
    if (this.authWebSocket) this.authWebSocket.close();
  }

  public user() {
    return this.userSub;
  }

  getDashboardRoute(lang: string) {
    if (this._user && this._user.accountType) {
      if (this._user.accountType === 'educator') {
        return `/${lang}/${this._user.accountType}/classrooms`
      }
      if (this._user.accountType === AccountType.MINISTRY_ADMIN || this._user.accountType === AccountType.BC_FSA_MINISTRY_ADMIN) {
        return `/${lang}/ministry-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_GRAD_MINISTRY_ADMIN) {
        return `/${lang}/ministry-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_GRAD_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_DIST_ADMIN) {
        return `/${lang}/dist-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      return `/${lang}/${this._user.accountType}/dashboard`
    }
    return `/`
  }

  getTimezone() {
    return 'America/Toronto';
  }

  public userIsStudent() {
    return this._user?.accountType === AccountType.STUDENT;
  }

  public checkUserAccountType(accountType: AccountType): boolean {
    if (!this._user) return false;
    return this._user.accountType === accountType;
  }

  public getDomain() {
    return window.location.origin;
  }

  public getReauthCompletedSub() {
    return this.reauthCompletedSub;
  }

  getApiAuthExpire() {
    this.apiAuthExpire;
  }

  public isLoggedIn() {
    return this.userSub.value ? true : false;
  }

  public isQcBranch() {
    return (this.whitelabel.getApiAddress() === API_ADDRESS_QC1)
  }

  public setKioskPassword(kioskPassword: string) {
    this.activeKioskPassword = kioskPassword;
  }

  public registerNoApiNetSub(apiNetFail: BehaviorSubject<boolean>) {
    apiNetFail.next(this.apiNetFail.getValue()); // usually this first one will only be around for a fraction of a second. in any case, it is private so nothing outside of this calss should be subscribing to it becaus it will be wiped out
    this.apiNetFail = apiNetFail;
  }
  private clearNetworkError = (res: any) => {
    this.apiNetFail.next(false);
    return res;
  }
  private catchNetworkError = (e) => {
    // console.log('catch net error', e.message)

    if (e.code === 500) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__RELOGIN) {
      this.apiAuthExpire.next(true); // not being used at the moment
      this.clearUser();
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    throw e;
  }

  private clearLocalSession() {

    this.api.authentication.removeAccessToken();
  }

  private refreshUserInfo = (res: IAuthRes, isSilent: boolean = false) => {
    this._user = {
      ...res.user || res.authentication.payload,
      accessToken: res.accessToken,
    }

    if (!isSilent) {
      this.userSub.next(this._user)
    }

    return res;
  }

  // temp: wrong place, just rrushing
  isStudentIntervalInitialized
  initStudentInterval() {
    if (!this.isStudentIntervalInitialized) {
      this.isStudentIntervalInitialized = true;
      setInterval(this.refreshStudentData, 5 * 1000);
      this.refreshStudentData();
    }
  }
  refreshStudentData = () => {
    return this.apiCreate('public/educator/students', { type: 'STUDENT_INTERVAL' }).then()
  }

  private getToken = (res: IAuthRes) => {
    return res.accessToken;
  }

  getDisplayName() {
    if (this._user) {
      return this._user.firstName + ' ' + this._user.lastName
    }
    return 'Not Logged In';
  }

  getUid() {
    return this._user?.uid || -1;
  }


  uploadFile(file: File | Blob, filename: string, purpose: string = '_general', isPermaLink: boolean = false): Promise<IUploadResponse> {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';
    formData.append('form_upload', file, filename);
    formData.append('uid', '' + uid);
    console.log("started uploading file with user id", uid);
    formData.append('purpose', purpose);
    formData.append('isPermaLink', isPermaLink ? '1' : '0');
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/upload', formData)
      .toPromise()
      .then((res) => { console.log(res); return <IUploadResponse>res });
  }

  excelToJson(file: File) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('form_upload', file);
    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/convert-xlsx-to-json', formData)
      .toPromise()
  }


  public refreshToken = (): Promise<any> => {
    return this.api
      .reAuthenticate(true)
      .then(userInfo => this.refreshUserInfo(userInfo, true))
      .then(function (result) {
        console.log('reAuth success', result)
        return result;
      }).catch(function (err) {
        console.log('reAuth err', err);
        throw err;
      })
  }

  reportFilePath(path: string, raw: string, filename: string) {
    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.xlsx?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  textFilePath(path: string, raw: string, filename: string, fileExtension: string) {
    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.' + fileExtension + '?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  dataFilePath(path: string, options: { [key: string]: string | number }) {
    let optionStr = '';
    if (options) {
      Object.keys(options).forEach(param => optionStr += '&' + param + '=' + options[param]);
    }
    return this.whitelabel.getApiAddress() + '/data-frame.xlsx?uid=' + this._user?.uid + '&path=' + path + optionStr + '&jwt=' + this._user?.accessToken;
  }

  jsonToExcel(records: any[], fileName) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    const jsonRecords = JSON.stringify(records);
    const recordsBlob = new Blob([jsonRecords], { type: 'application/json' });
    formData.append('form_upload', recordsBlob);
    formData.append('fileName', fileName)
    return this.httpClient.post(this.whitelabel.getApiAddress() + '/upload-json-as-xlsx', formData).toPromise();
  }

  // public refreshToken = () =>{
  // if (this._user && !this.isLoggingIn){
  //   this.apiFind('/rest/auth/refresh-token', {})
  //     .then(refreshed => {
  //       this.api.authentication.setAccessToken(refreshed[0]);
  //       // setTimeout(()=>{
  //       //   console.log(['refreshToken', refreshed[0], this.api.authentication.getAccessToken()])
  //       // }, 1000)
  //     })
  //     .catch(()=>{
  //       console.log('soft fail')
  //       return this.api
  //         .reAuthenticate(true)
  //         .then(res => this.refreshUserInfo(res, true))
  //     })
  //     .catch(e =>{
  //       console.log('hard fail')
  //       return this.clearUser(e);
  //     }
  // }
  // }

  clearUser = (e?: any) => {
    this.userSub.next(null);
    this._user = null;
  }

  public reauth(): Promise<any> {
    return this.api
      .reAuthenticate()
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(res => { this.reauthCompletedSub.next(true); return res })
      .then(() => {
        this.initAuthWebSocket();
      })
      .catch(e => { this.reauthCompletedSub.next(true); return e })
      .catch(this.catchNetworkError)
      .catch(e => { if (e.message !== F_ERR_MSG__REAUTH_NO_TOKEN) { throw e; } }) // no token is not really an error since we are not checking for it in the first place
  }

  public loginWithKey(key: string): Promise<any> {
    this.isLoggingIn = true;

    return this.api.authenticate({
      strategy: 'loginMarkerKey',
      secret_key: key
    }).then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => {
        this.isLoggingIn = false;
        this.initAuthWebSocket();
      })
      .catch(this.catchNetworkError)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public login(email: string, password: string): Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => {
        this.isLoggingIn = false;
        this.initAuthWebSocket();
      })
      .catch(this.catchNetworkError)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public loginStudentGrad(stage: 1 | 2, studentNumber: string, accessCode: string, districtSlug?: string, schoolSlug?: string): Promise<any> {
    this.isLoggingIn = true;

    if (stage === 1) {
      return this.apiCreate('public/student/name-initial', {
        studentNumber,
        accessCode,
        districtSlug,
        schoolSlug,
        assessmentType: 'grad',
      }, {
        query: {
          action: 'get last name',
        }
      }).then(data => {
        return data;
      });
    } else {
      return this.api
        .authenticate({
          strategy: 'loginSessionKey',
          stage,
          studentNumber,
          accessCode,
          districtSlug,
          schoolSlug,
          assessmentType: 'grad',
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .catch(this.catchNetworkError)
        .catch(err => { this.isLoggingIn = false; throw err; })
    }

  }

  public loginStudentFsa(stage: 1 | 2, studentNumber: string, accessCode: string, districtSlug?: string, schoolSlug?: string): Promise<any> {
    this.isLoggingIn = true;

    if (stage === 1) {
      return this.apiCreate('public/student/name-initial', {
        studentNumber,
        accessCode,
        districtSlug,
        schoolSlug,
        assessmentType: 'fsa',
      }, {
        query: {
          action: 'get names',
        }
      }).then(data => {
        return data;
      });
    } else {
      return this.api
        .authenticate({
          strategy: 'loginSessionKey',
          stage,
          studentNumber,
          accessCode,
          districtSlug,
          schoolSlug,
          assessmentType: 'fsa',
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .catch(this.catchNetworkError)
        .catch(err => { this.isLoggingIn = false; throw err; })
    }

  }

  public loginFieldTest(studentNumber: string, accessCode: string, asmtSlug: string, selectedSchoolId: string, selectedDistrictId: string): Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'loginFieldTest',
        studentNumber,
        accessCode,
        asmtSlug,
        selectedSchoolId,
        selectedDistrictId,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  private persistPresence(userInfo) {

    return userInfo
  }

  public getJWT(email: string, password: string): Promise<any> {
    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.getToken)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public logout(): Promise<any> {

    if (this.authWebSocket) this.authWebSocket.close();


    return this.api
      .logout()
      .then(this.clearNetworkError)
      .then(this.clearUser())
      .catch(e => { this.clearLocalSession(); throw e; })
      .catch(this.catchNetworkError)
  }

  public apiGet(route: string, id: string | number, params?: any): Promise<any> {
    return this.api
      .service(route)
      .get(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiFind(route: string, params?: any): Promise<any> {
    return this.api
      .service(route)
      .find(params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiCreate(route: string, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .create(data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiPatch(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .patch(id, data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiUpdate(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .update(id, data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiRemove(route: string, id: string | number, params?: any): Promise<any> {
    return this.api
      .service(route)
      .remove(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }


  initAuthWebSocket() {
    this.authWebSocket = new Sockette(this.authWebSocketURI, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        this.onOpenAuthWS(e);
      },
      onmessage: e => {
        // console.log("Received message: ", e);
        this.onMessageAuthWS(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => this.onCloseAuthWS(e),
      onerror: e => console.log('Error:', e)
    });
  }

  onOpenAuthWS(e) {
    this.authWebSocket.json({ action: "authConnect", data: { uid: this.user().value.uid } });
  }

  onCloseAuthWS(e) {
    this.authWebSocket.json({ action: "authDisconnect" });
  }

  onMessageAuthWS(e) {
    let data;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
    }

    if (!data && e.data == "KICK") {
      console.warn("Someone else may have logged into this account.");
      // alert("Someone else logged into this account. You will now be logged out.");
      // this.logout();
    }
  }

  isSchoolAdmin(accountType: AccountType): boolean {
    return [
      AccountType.BC_FSA_SCHOOL_ADMIN,
      AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_SCHOOL_ADMIN,
      AccountType.SCHOOL_ADMIN,
    ].includes(accountType);
  }

  isScoreEntrySchoolAdmin(accountType: AccountType): boolean {
    return accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY;
  }

  isScoreEntryDistrictAdmin(accountType: AccountType): boolean {
    return accountType === AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY;
  }

  isDistrictAdmin(accountType: AccountType): boolean {
    return [
      AccountType.DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_DIST_ADMIN,
    ].includes(accountType);
  }

  isMinistryAdmin(accountType: AccountType): boolean {
    return [
      AccountType.MINISTRY_ADMIN,
      AccountType.BC_GRAD_MINISTRY_ADMIN,
      AccountType.BC_FSA_MINISTRY_ADMIN,
    ].includes(accountType);
  }

}
