import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IContentElementResultsPrint, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';

@Component({
  selector: 'element-render-results-print',
  templateUrl: './element-render-results-print.component.html',
  styleUrls: ['./element-render-results-print.component.scss']
})


export class ElementRenderResultsPrintComponent implements OnInit, OnChanges {

  @Input() element:IContentElementResultsPrint;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter:number;

  // response: string;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
  isVisible() {
    return this.questionPubSub && this.questionPubSub.isPrintMode;
  }

  getResponse() {
    if(this.questionState && this.element.inputEntryId && this.questionState[this.element.inputEntryId]) {
      return this.questionState[this.element.inputEntryId].str;
    }
  }
}
